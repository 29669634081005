// components/Slug.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Slug = () => {
  const { slug } = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRedirectUrl = async () => {
      try {
        const response = await fetch(`/api/slug/${slug}`);
        if (response.ok) {
          const data = await response.json();
          const fetchedUrl = data.url;

          if (isValidUrl(fetchedUrl)) {
            setRedirectUrl(fetchedUrl);
          } else {
            setError("Invalid URL received from the API");
          }
        } else {
          setError("Failed to fetch redirect URL");
        }
      } catch (error) {
        console.error("Error fetching redirect URL", error);
        setError("An error occurred while fetching the URL");
      }
    };

    fetchRedirectUrl();
  }, [slug]);

  useEffect(() => {
    if (redirectUrl) {
      // Redirect to the obtained URL
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <div>{error ? <div>Error: {error}</div> : <div>Redirecting...</div>}</div>
  );
};

export default Slug;

// // components/NotFound.js
// import React, { useEffect } from "react";
// import { useParams } from "react-router-dom";

// const slugList = {
//   g: "https://google.com",
//   f: "https://facebook.com",
//   l: "https://linkedin.com",
//   // Add more slugs and URLs as needed
//   default: "https://gouravkumar.dev", // Default URL if the slug is not found in the list
// };

// const Slug = () => {
//   const { slug } = useParams();

//   useEffect(() => {
//     const redirectUrl = slugList[slug] || slugList.default;
//     window.location.href = redirectUrl;
//   }, [slug]);

//   return <div>Redirecting...</div>; // You can customize the loading message
// };

// export default Slug;
